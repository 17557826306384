import React from "react";
import LazyLoad from "vanilla-lazyload";
import classNames from "classnames";

const lazyloadConfig = {
  elements_selector: ".lazy",
};

// Only initialize it one time for the entire application
if (typeof window !== "undefined") {
  if (!document.lazyLoadInstance) {
    document.lazyLoadInstance = new LazyLoad(lazyloadConfig);
  }
}

export class LazyBackground extends React.Component {
  // Update lazyLoad after first rendering of every image
  componentDidMount() {
    document.lazyLoadInstance.update();
  }

  // Update lazyLoad after rerendering of every image
  componentDidUpdate() {
    document.lazyLoadInstance.update();
  }

  // Just render the image with data-src
  render() {
    const { url, children, style, className } = this.props;
    const divClass = classNames("lazy", className);

    return (
      <div className={divClass} data-bg={url} style={style}>
        {children}
      </div>
    );
  }
}

export default LazyBackground;
