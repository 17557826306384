import React from "react";
import { Trans } from "@lingui/macro";
import Link from "~components/link";
import ownPace from "../../images/why-us/own-pace.svg";
import unlimitAccess from "../../images/why-us/unlimit-access.svg";
import expertTeachers from "../../images/why-us/expert-teachers.svg";
import certificate from "../../images/why-us/certificate.svg";

const WhyChooseUs = () => {
  return (
    <div className="text-center md:text-left mt-6">
      <h1 className="text-xl md:text-3xl lg:text-4xl">
        <Trans>Why choose us?</Trans>
      </h1>
      <div className="flex flex-col mt-6 leading-loose text-gray-800 justify-between md:flex-row md:mt-12 text-center">
        <div className="px-3">
          <img
            src={ownPace}
            alt="Learn at your own pace"
            className="inline-block mb-4"
          />
          <h4 className="font-medium">
            <Trans>Learn at your own pace</Trans>
          </h4>
          <p className="mt-2 md:text-left text-xs text-brand-link-gray md:text-sm">
            <Trans>
              Enjoy courses from the comfort of your own home. No schedules or
              deadlines.
            </Trans>
          </p>
        </div>
        <div className="px-3 mt-8 md:mt-0">
          <img
            src={unlimitAccess}
            alt="Unlimited Access"
            className="inline-block mb-4"
          />
          <h4 className="font-medium">
            <Trans>Unlimited access</Trans>
          </h4>
          <p className="mt-2 md:text-left text-xs text-brand-link-gray md:text-sm">
            <Trans>
              High quality videos with no stress of losing your place. Unlimited
              access so you can rewatch lessons as many times as you want.
            </Trans>
          </p>
        </div>
        <div className="px-3 mt-8 md:mt-0">
          <img
            src={expertTeachers}
            alt="Quality teachers"
            className="inline-block mb-4"
          />
          <h4 className="font-medium">
            <Trans>Quality teachers</Trans>
          </h4>
          <p className="mt-2 md:text-left text-xs text-brand-link-gray md:text-sm">
            <Trans>
              Teachers will in depth knowledge and experience share their
              insider know-how with you in every lesson.
            </Trans>
          </p>
        </div>
        <div className="px-3 mt-8 md:mt-0">
          <img
            src={certificate}
            alt="Completion Certificate"
            className="inline-block mb-4"
          />
          <h4 className="font-medium">
            <Trans>Completion certificate</Trans>
          </h4>
          <p className="mt-2 md:text-left text-xs text-brand-link-gray md:text-sm">
            <Trans>
              Receive a course completion certificate upon finishing the course.
            </Trans>
          </p>
        </div>
      </div>
      <div className="content-center mt-10 text-center">
        <div
          style={{ maxWidth: 700 }}
          className="inline-block text-xs text-brand-link-gray md:text-sm"
        >
          <Trans>
            Browse through our catalogue of courses and filter by category and
            complexity bases on your need. Are you a subject matter expert?
            Create a course of you own and we can help you reach your target
            student audience through our advanced marketing campaigns.
          </Trans>
        </div>
        <div className="mt-10">
          <Link
            to="/courses"
            className="btn btn-primary w-3/4 mb-6 md:w-auto md:mb-0 md:mr-6 inline-block"
            style={{
              boxShadow: "0px 4px 34px rgba(247, 146, 37, 0.4)",
            }}
          >
            <span>
              <Trans>View all courses</Trans>
            </span>
            <span className="icon-chevron-right text-xs ml-2 font-bold" />
          </Link>
          <a
            href="https://blog.vtutor.com/es/cursos-en-linea/ganar-dinero-cursos-online-2021/"
            className="btn btn-primary-reverse w-3/4 md:w-auto inline-block"
          >
            <span>
              <Trans>Become an instructor</Trans>
            </span>
            <span className="icon-chevron-right text-xs ml-2 font-bold" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
