import React, { useState } from "react";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import validator from "validator";
import { useForm } from "react-hook-form";
import Bugsnag from "@bugsnag/js";
import InputError from "../inputError";
import { subscribeMailchimp } from "~/services/misc/subscribeMailchimp";

export default withI18n()(({ i18n }) => {
  const {
    register,
    handleSubmit,
    reset,

    formState: {
      errors,
    },
  } = useForm({ mode: "onBlur" });
  const [success, setSuccessState] = useState(false);
  const [loading, setLoadingState] = useState(false);

  const submit = async (body, e) => {
    setLoadingState(true);

    try {
      const result = await subscribeMailchimp(body.subscribe1);

      if (result) {
        setSuccessState(true);
        reset();
      }
    } catch (e) {
      Bugsnag.notify(e);
      console.log(e);
    }

    setLoadingState(false);
  };

  return (
    <div className="px-6 md:pl-12 xl:pt-4 xl:pb-20 xl:pl-0 absolute md:max-w-md lg:max-w-full lg:pt-1">
      <h1 className="text-gray-900 mb-6 mt-8 md:text-3xl lg:text-4xl xl:text-5xl md:mt-16 md:mb-6">
        <Trans>
          Get exclusive <span className="text-white">discounts</span>
        </Trans>
      </h1>
      <p className="mb-8 text-gray-800 text-xs md:text-base  md:max-w-3/4 lg:max-w-full">
        <Trans>
          Subscribe to our mailing list and hear about our latest promotions and new courses.
        </Trans>
      </p>
      <form>
        <div
          className="flex border border-gray-400 inputBox rounded-full bg-white md:max-w-3/4 lg:max-w-md"
          // style={{ width: 455 }}
          style={{
            boxShadow: " 0px 4px 40px rgba(63, 65, 69, 0.5)",
          }}
        >
          <input
            type="email"
            {...register('subscribe1', {
              required: true,
              validate: (value) =>
                validator.isEmail(value) || i18n._(t`Invalid e-mail address`),
            })}
            className="w-full p-2 ml-5"
            placeholder={i18n._(t`Enter your email`)}
            autoComplete="off"
            disabled={loading} />
          <button
            style={{ width: 50 }}
            className="p-2 m-1 text-center text-brand-primary text-base"
            type="submit"
            onClick={handleSubmit(submit)}
            disabled={loading}
          >
            <span className="icon-arrow-right" />
          </button>
        </div>
      </form>
      <InputError errors={errors} field="subscribe1" className="text-lg pt-2" />
      {success && (
        <div className="text-white pt-2 text-lg font-semibold ml-3">
          <Trans>Confirmed, you are now signed up</Trans>
        </div>
      )}
    </div>
  );
});
