import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { withI18n } from "@lingui/react";
import Link from "./link";
import categoriesList from "../locales/es/filters.json";

const TopCategories = ({ i18n }) => {
  const data = useStaticQuery(graphql`
    query {
      allVtutorStructuredContent {
        distinct(field: permalink___category)
      }
    }
  `);

  const categories = data.allVtutorStructuredContent.distinct;

  return (
    <div className="flex flex-wrap justify-center">
      {categories.map((val, idx) => {
        const label =
          i18n.language === "es" && typeof categoriesList[val] !== "undefined"
            ? categoriesList[val].replace(/-/g, " ")
            : val.replace(/-/g, " ");

        return (
          <Link
            key={`tcat_${idx}`}
            to={`/courses/${val}`}
            className="bg-white rounded-full mx-3 mr-2 mb-4 hover:text-gray-900 text-brand-link-gray no-underline capitalize block px-4 py-2 text-xs  md:px-10 md:py-3 md:text-sm"
            style={{
              border: "1px solid #F0F0F0",
              boxSizing: "border-box",
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.02)",
            }}
          >
            {label}
          </Link>
        );
      })}
    </div>
  );
};

export default withI18n()(TopCategories);
