import React from "react";
import { Trans } from "@lingui/macro";
import LazyBackground from "~components/lazyBackground";

const BecomeAnInstructor = () => {
  return (
    <LazyBackground
      className="mt-4"
      url="https://res.cloudinary.com/ie1/image/upload/f_auto,fl_progressive:steep,q_auto:best/v1605718688/vtutor/CABECERA-TUTORES-1900x340-1_xxzjof.png"
      style={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    >
      <div className="text-left leading-none py-16 px-6 md:mr-auto md:ml-auto xl:max-w-6xl xl:px-0">
        <div className="md:text-left text-white w-full">
          <h1 className="font-extrabold text-3xl max-w-3/4 md:text-5xl lg:text-6.5xl text-white">
            <Trans>
              Become an <span className="text-brand-primary">instructor</span>
            </Trans>
          </h1>
          <div className="font-normal">
            <p className="mt-6 text-xs md:text-base max-w-1/2 lg:max-w-lg">
              <Trans>
                Create an online video course and earn money by teaching people
                around the world.
              </Trans>
            </p>
            <a
              href="https://blog.vtutor.com/es/cursos-en-linea/ganar-dinero-cursos-online-2021/"
              className="btn btn-primary w-full font-medium md:w-auto py-4 px-8 inline-block mt-6"
            >
              <span>
                <Trans>Become an instructor</Trans>
              </span>
              <span className="icon-chevron-right text-xs ml-3 font-bold" />
            </a>
          </div>
        </div>
      </div>
    </LazyBackground>
  );
};

export default BecomeAnInstructor;
