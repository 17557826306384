import React from "react";
import { Trans } from "@lingui/macro";
import ReviewsList from "~components/reviewsList";

const Reviews = () => {
  return (
    <div
      className="mt-4"
      style={{
        backgroundImage:
          "linear-gradient(rgba(241, 244, 245, 100%), rgba(241, 244, 245, 0%))",
      }}
    >
      <div className="text-center md:text-left p-2 mb-12">
        <div className="mt-3">
          <div className="mt-2 py-3 md:mr-auto md:ml-auto lg:max-w-6xl">
            <h1 className="text-xl mb-3  md:mb-12 md:text-3xl lg:text-4xl">
              <Trans>What our students say about us</Trans>
            </h1>
            <div className="p-4">
              <ReviewsList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
