import React, { useEffect } from "react";
import Layout from "~/components/layout";
import SEO from "~/components/seo";
import PopularCourses from "~/components/popularCourses";
import NewArrivalsCourses from "~/components/newArrivalsCourses";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import Reviews from "~components/home/reviews";
import Link from "~/components/link";
import TopCategories from "~/components/home/topCategories";
import LazyBackground from "~/components/lazyBackground";
import LookingFreeCourse from "~/components/index/lookingFreeCourse";
import HeaderBanner from "~components/home/headerBanner";
import WhyChooseUs from "~components/home/whyChooseUs";
import BecomeAnInstructor from "~components/home/becomeAnInstructor";
import FeatureIn from "~components/home/featureIn";

const IndexPage = (props) => {
  const { i18n } = props;
  const paths = props.pageContext.paths;

  useEffect(() => {
    return () => {
      return false;
    };
  }, []);

  return (
    <Layout paths={paths} style={{ background: "rgba(241, 244, 245, .3)" }}>
      <SEO
        title={i18n._(
          t`vTutor online courses | Online learning platform – The best courses for improving your skills`
        )}
        lang={props.pageContext.locale}
        keywords={[`vtutor`, `cursos`, `courses`, `tutorials`]}
        location={paths}
        description={i18n._(
          t`With vTutor you can learn at your own pace. Find a great selection of courses for your entrepreneurship education.`
        )}
      />
      <HeaderBanner />
      <div className="py-3 md:mr-auto md:ml-auto lg:max-w-6xl">
        <div className="text-left rounded p-4">
          <div>
            <div className="flex items-center justify-between">
              <h1 className="text-lg md:text-2xl">
                <Trans>Most popular courses</Trans>
                {/* <Trans>New courses</Trans> */}
              </h1>
              <Link to="/courses">
                <span className="space-x-2 flex items-center xl:text-xl whitespace-nowrap">
                  <span>
                    <Trans>View all categories</Trans>
                  </span>
                  <span className="icon-th-list" />
                </span>
              </Link>
            </div>
            <div className="mt-2">
              <PopularCourses />
              {/* <NewArrivalsCourses /> */}
              <div className="hidden md:mt-4 md:block">
                <PopularCourses row2={true} />
              </div>
              <div className="text-center mt-12">
                <Link
                  to="/courses"
                  className="no-underline btn btn-primary py-2 xl:py-3 inline-block w-full md:w-1/3 md:text-2xl"
                >
                  <span className="mr-2 align-middle lg:tracking-wide">
                    <Trans>View all courses</Trans>
                  </span>
                  <span className="icon-chevron-right text-xs ml-2 font-bold" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeatureIn />
      <TopCategories />
      <Reviews />
      <div
        className="mt-16 md:mt-48 lg:mt-32 xl:24 clearfix"
        style={{
          minHeight: 337,
          background:
            "linear-gradient(108.83deg, #EFA71C 0.62%, #F79225 48.56%, #F49732 103.11%)"
        }}
      >
        <div className="text-center md:text-left md:mr-auto md:ml-auto lg:max-w-6xl leading-none w-auto">
          <div className="relative">
            <LookingFreeCourse />
            <LazyBackground
              className="hidden md:block absolute -mt-26 right-0 z-0"
              url="https://res.cloudinary.com/ie1/image/upload/e_auto_color,f_auto/v1585760484/vtutor/looking-for-free-courses.png"
              style={{
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
                height: 480,
                width: 460
              }}
            ></LazyBackground>
          </div>
        </div>
      </div>
      <div className="mt-10 py-3 md:mr-auto md:ml-auto lg:max-w-6xl">
        <div className="text-left p-4 relative">
          <h1 className="text-xl md:text-2xl">
            {/* <Trans>Most popular courses</Trans> */}
            <Trans>New courses</Trans>
          </h1>
          <div className="mt-1">
            {/* <PopularCourses /> */}
            <NewArrivalsCourses />
          </div>
          {/* <div className="hidden md:mt-4 md:block">
            <PopularCourses row2={true} />
          </div> */}
        </div>
      </div>
      <BecomeAnInstructor />
      <div
        className="p-2 pr-3 mb-12"
        style={{
          backgroundImage:
            "linear-gradient(rgba(241, 244, 245, 100%), rgba(241, 244, 245, 0%))"
        }}
      >
        <div className="md:mr-auto md:ml-auto lg:max-w-6xl">
          <WhyChooseUs />
        </div>
      </div>
    </Layout>
  );
};

export default withI18n()(IndexPage);
