import React from "react";
import { Trans } from "@lingui/macro";
import TopCategoriesList from "~components/topCategories";
import Link from "~components/link";

const TopCategories = () => {
  return (
    <div className="bg-white">
      <div className="mt-2 py-3 md:mr-auto md:ml-auto lg:max-w-6xl">
        <div className="mt-10 text-center md:text-left">
          <h1 className="mb-6 text-xl">
            <Trans>Top Categories</Trans>
          </h1>
          <TopCategoriesList />
          <div className="mt-10 text-center">
            <Link
              to="/courses"
              className="text-base text-gray-700 hover:text-gray-800 font-bold no-underline"
            >
              <span className="mr-2 align-middle">
                <Trans>View all categories</Trans>
              </span>
              <span className="icon-chevron-right text-xs ml-2 font-bold" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopCategories;
