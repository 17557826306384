import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import EmblaCarousel from "./EmblaCarousel";
import Review from "./reviewCard";
import PricesContext from "../providers/pricesProvider";
import usePrices from "../hooks/usePrices";

export default function ReviewsList() {
  const reviews = useReviews().edges;

  // ACA es para los precios
  const ids = reviews.map((review) => review.node.course.ID);
  const joinedIds = ids.join(",");

  const { data } = usePrices(joinedIds);

  const reviewsCarrousel = reviews.map((review, i) => {
    const reviewData = review.node;

    return <Review key={`id_${i}`} {...reviewData} {...reviewData.course} />;
  });

  return (
    <PricesContext.Provider value={data}>
      <EmblaCarousel enableDots={true}>{reviewsCarrousel}</EmblaCarousel>
    </PricesContext.Provider>
  );
}

const useReviews = () => {
  const data = useStaticQuery(graphql`
    query Reviews {
      allReviews(limit: 8) {
        edges {
          node {
            course {
              ID
              title
              reviews_avg
              reviews
              free
              permalink {
                category
                slug
              }
              image
            }
            avatar
            name
            opinion
            stars
          }
        }
      }
    }
  `);

  return data.allReviews;
};
