import React from "react";
import { Trans } from "@lingui/macro";
import LazyImage from "~components/lazyImage";

const FeatureIn = () => {
  return (
    <div
      className="mt-4"
      style={{
        backgroundImage: "linear-gradient(rgba(241, 244, 245, 100%), rgba(241, 244, 245, 0%))",
      }}
    >
      <div className="mt-2 py-3 md:mr-auto md:ml-auto lg:max-w-6xl">
        <div className="my-3">
          <h1 className="text-center text-xl mb-2 md:text-left md:mb-3 md:text-3xl lg:text-4xl">
            <Trans>As featured in</Trans>
          </h1>
          <div className="flex flex-col items-center justify-center md:flex-row md:space-x-6">
            <LazyImage
              src={`https://res.cloudinary.com/ie1/image/upload/e_bgremoval/v1639501144/vtutor/featuredIn/vtfb_nousu2.png`}
              alt=""
              style={{
                // marginTop: "-6px",
                // width: 140,
                height: 40,
              }}
            />
            <LazyImage
              src={`https://res.cloudinary.com/ie1/image/upload/v1618227097/vtutor/featuredIn/logp_pcworld_zfmdrc.png`}
              alt=""
              style={{
                // width: 140,
                height: 60,
              }}
            />
            <LazyImage
              src={`https://res.cloudinary.com/ie1/image/upload/v1618227097/vtutor/featuredIn/emagister_eoj6ix.png`}
              srcBg
              alt=""
              style={{
                marginTop: 10,
                // width: 160,
                height: 70,
              }}
            />
            <LazyImage
              src={`https://res.cloudinary.com/ie1/image/upload/v1618325359/vtutor/featuredIn/awsedstart_1_buupjw.png`}
              alt=""
              style={{
                // width: 132,
                height: 70,
              }}
            />
            <LazyImage
              src={`https://res.cloudinary.com/ie1/image/upload/v1618313741/vtutor/featuredIn/hotsale_hk8vb5.png`}
              alt=""
              style={{
                // marginTop: "-12px",
                // width: 130,
                height: 70,
              }}
              className="mt-3 mb-6 md:-mt-3 md:mb-0"
            />
            <LazyImage
              src={`https://res.cloudinary.com/ie1/image/upload/v1618325509/vtutor/featuredIn/logo_el_mundo_hckp1k.png`}
              alt=""
              style={{
                // width: 180,
                height: 30,
              }}
            />
            <LazyImage
              src={`https://res.cloudinary.com/ie1/image/upload/v1618227097/vtutor/featuredIn/el_pais_tummfs.png`}
              alt=""
              style={{
                marginTop: "-2px",
                // width: 140,
                height: 65,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureIn;
