import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import Rating from "./rating";
import Link from "./link";
import ParagraphShortening from "./paragraphShortening";
import LazyImage from "./lazyImage";
import Avatar from "./avatar";
import Sanitize, { sanitize } from "./sanitize";
import PricesContext from "../providers/pricesProvider";
import Loader from "./loader";

const ReviewCard = React.memo(({ ID, name, avatar, opinion, image, title, free, reviews_avg, permalink }) => {
  const prices = useContext(PricesContext);
  const [price, setPrice] = useState("");
  const [price_old, setprice_old] = useState("");
  const [symbol, set_symbol] = useState("");

  useEffect(() => {
    if (prices !== null && prices[ID] !== undefined) {
      setPrice(prices[ID].price);
      setprice_old(prices[ID].price_old);
      set_symbol(prices.currency.symbol);
    }

    return () => {
      return false;
    };
  }, [prices, ID]);

  const isDescounted = () => {
    return price_old !== "" && price !== price_old;
  };

  const formatName = (name) => {
    const splitted = name.split(" ");

    return (`${splitted[0]} ${splitted[splitted.length - 1]}`).toLowerCase();
  };

  return (
    <Link
      className="text-gray-800 flex flex-col no-underline text-left review-card p-2"
      style={{ height: 280, maxWidth: 345 }}
      to={`/course/${permalink.category}/${permalink.slug}/`}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center flex-1">
          <Avatar url={avatar} name={name} />
          <span className="text-sm text-gray-700 truncate capitalize">
            {/* <ParagraphShortening text={name} /> */}
            {formatName(name)}
          </span>
        </div>
        <Rating reviews_avg={reviews_avg} className="justify-center text-sm" />
      </div>
      <div className="py-4 h-32 italic text-base text-gray-700 overflow-y-auto lowercase capitalize-letter">
        <ParagraphShortening text={opinion} howMuch={200} />
      </div>
      <div className="mt-3 bg-white rounded-brand flex" style={{ height: 100 }}>
        <div>
          <LazyImage
            src={`https://res.cloudinary.com/ie1/image/fetch/f_auto,w_173,h_150,c_fill,g_auto,q_auto:best/${image}`}
            lazyBgUrl={`https://res.cloudinary.com/ie1/image/fetch/f_auto,w_1,h_1,q_auto:low/${image}`}
            alt={sanitize(title)}
            style={{
              width: 115,
              height: 100,
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            }}
          />
        </div>
        <div className="p-2 md:p-3 flex flex-col flex-1">
          <span className=" text-gray-800 font-semibold text-sm flex-1 leading-tight md:leading-normal">
            <Sanitize html={title} className="line-clamp-2" />
          </span>
          <div className="flex items-center md:justify-between">
            {price === "" ? (
              <div className="text-center w-full pt-2">
                <Loader reverse={true} />
              </div>
            ) : (
              <div className="flex flex-col flex-1 justify-end text-right md:flex-row md:space-x-2 md:items-center">
                <span className="line-through text-gray-600 text-sm hidden md:inline-block">
                  {!free && price !== "" && isDescounted() && (
                    <>
                      {price_old} {symbol}
                    </>
                  )}
                </span>
                <span className="text-brand-primary text-base font-semibold">
                  {free ? (
                    <Trans>Free</Trans>
                  ) : (
                    <>
                      {price} {symbol}
                    </>
                  )}
                </span>
              </div>
            )}
            <span className="icon-chevron-right text-xs ml-2 text-brand-primary" />
          </div>
        </div>
      </div>
    </Link>
  );
});

ReviewCard.propTypes = {
  ID: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  opinion: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  free: PropTypes.bool.isRequired,
  reviews_avg: PropTypes.number.isRequired,
  permalink: PropTypes.object.isRequired,
};

export default ReviewCard;
