import React from "react";
import { Trans } from "@lingui/macro";
import Instant from "~components/search/instant";

const HeaderBanner = () => {
  return (
    <div id="homeBanner">
      <div className="px-6 pt-6 pb-10 md:pt-0 md:mr-auto md:ml-auto lg:max-w-6xl md:pb-16 xl:px-0">
        <div className="text-left leading-tight md:pt-16 md:text-left  md:w-8/12 xl:w-8/12">
          <div className="text-3xl md:text-6xl lg:text-6xl">
            <Trans>
              <span className="text-white font-semibold">Effective Education</span>
            </Trans>
          </div>
          <div className="text-white text-2xl font-semibold md:text-4xl lg:text-5xl">
            <Trans>Learn and Launch</Trans>
          </div>
          <p className="mt-4 font-semibold text-white text-base max-w-3/4 md:text-lg md:mt-6">
            <Trans>Join the network of over 14,000 registered students</Trans>
          </p>
          <div className="mt-8">
            <Instant className="max-w-md mb-3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBanner;
